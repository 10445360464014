

/* top layout */
.layout_top {
    display: flex;
    position: fixed;
    top: 0;
    left: 0rem;
    right: 0rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background: var(--app-white);
    padding: 10px 20px;
    margin-left: auto;
    margin-right: auto;
    z-index: 9;
}

.layout_top  section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    color: var(--app-primary);
    font-weight: 500;
}

.top_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    gap: 10px;
}

.top_icon .top_icon_label {
    display: none;
}

.layout_footer {
    position: fixed;
    bottom: 0rem;
    left: 0rem;
    right: 0rem;
    width: 100%;
    background: var(--app-white);
    padding: 0rem 20px;
    margin-left: auto;
    margin-right: auto;
    z-index: 21;
}

.layout_nav_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}


.layout_nav_active {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--app-yellow);
    border-radius: 50%;
    padding: 10px;
}

.layout_nav_active svg {
    color: var(--app-primary);
}

.layout_nav_active span {
    display: none;
}

.layout_nav {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0.5rem;
    border-radius: 50%;
    color: var(--app-title-gray);
}

.layout_nav span {
    display: block;
    font-size: 12px;
    font-weight: 400;
}


.layout_scroll {
    overflow-y: auto;
    height: 100%;
    margin-bottom: 4rem;
    overflow-x: hidden;
}




@media (min-width: 640px) {
    .top_icon .top_icon_label { display: flex;}
}




@media (min-width: 768px) {
    .layout_footer,
    .layout_top {
        width: 500px;
        z-index: 2;
    }

    .layout_scroll {
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .top_icon .top_icon_label { display: flex;}
}