

.home_activity {
    display: grid;
    grid-template-columns: repeat(4, minmax(0,1fr));
    background: var(--app-white);
    border-radius: 8px;
    padding: 10px;
    margin-top: 3rem;
    gap: 10px;
}

.home_activity section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.home_activity section span {
    color: var(--app-primary);
    font-size: 14px;
    font-weight: 400;
    text-align: center;
}

.home_marquee {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0;
    gap: 10px;
    font-size: 14px;
    font-weight: 400;
}

@keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }


.home_video {
    max-width: 100%;
    border-radius: 8px;
  }
  
  .home_video img {
    width: 100%;
    height: 200px;
    border-radius: 8px;
    animation: fade 1s ease-in-out;
    transition: 1 1s ease-in-out;
}


.home_quant_wrapper {
    padding-top: 1rem;
}

.home_quant_wrapper .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-left: 2px solid var(--app-yellow);
}

.home_quant_wrapper .title span {
    padding-left: 10px;
    color: var(--app-primary);
    font-size: 14px;
    font-weight: 500;
}

.home_quant {
    background: var(--app-white);
    border-radius: 8px;
    padding: 20px;
    margin-top: 0.5rem;
}


.home_quant section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--app-primary);
    font-size: 12.5px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.154);
    padding: 5px 0rem;
    font-weight: 400;
}


.home_tab {
    background: var(--app-yellow);
    margin: 1rem 0rem;
    width: 100%;
}


.ant-segmented .ant-segmented-item-selected {
    background: var(--app-white) !important;
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
    position: relative !important;
}

.ant-segmented .ant-segmented-group {
    display: flex;
    justify-content: space-between !important;
    align-items: center !important;
}


.home_message_news {
    width: 100%;
    background: var(--app-white);
    border-radius: 8px;
    margin: 1rem 0rem;
    padding: 20px;
}

.home_message_news img {
    width: 100%;
    height: 100%;
}



/* invite friends */
.home_invite_friends {
    position: relative;
    background-image: url("../images//barCode_Bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px;
    border-radius: 15px;
    height: 35rem;
    width: 100%;
    overflow: hidden;
}

.home_invite_friends_code {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--app-primary);
    padding: 10px 20px;
    bottom: 0;
    left: 0;
    right: 0;
    gap: 4rem;
    width: 100%;
    color: var(--app-white);
}


.home_invite_friends .code_url .auth__input {
    width: 100%;
}


.home_invite_friends .code_url {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.home_invite_friends_code b {
    color: var(--app-yellow);
    font-weight: 700;
}

.bar_code_reader {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 8px solid var(--app-barCode);
    padding: 10px;
    width: 15rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4.5rem;
}





/* home download */
.home_download {
    position: relative;
    background: url("../images//downloadApp_bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    padding: 20px;
}

.download_title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--app-white);
    padding-top: 3rem;
    padding-bottom: 1rem;
}

.home_download .codes {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 6px solid var(--app-barCode);
    padding: 10px;
    width: 12rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
}


.Download_Btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--app-barCode);
    padding: 20px 10px;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    font-weight: 500;
    gap: 10px;
}


.home_download p {
    color: var(--app-barCode);
    text-align: justify;
    padding-top: 1rem;
}





@media (min-width: 1024px) {
    .home_video {
        max-width: 480px;
    }

    .bar_code_reader {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 8px solid var(--app-barCode);
        padding: 10px;
        width: 15rem;
        margin-left: auto;
        margin-right: auto;
        margin-top: 6rem;
    }
}