

/* PROFILE CSS */
.invitation_code_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.invitation_code {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.invitation_code .user_img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid var(--app-white);
}

.invitation_code .user_img  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.invitation_code article {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.invitation_code article span:nth-child(1) {
    color: var(--app-primary);
    font-weight: 500;
    font-size: 16px;
}

.invitation_code article span:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--app-gray);
    gap: 10px;
}

.invite_friend_wrapper {
    background: var(--app-bg-linear);
    border-radius: 15px;
}

.invite_friends {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: var(--app-primary);
    font-size: 14px;
}

.invite_friends section {
    display: flex;
    justify-content: center;
    align-items: center;
}

.friends_upgrade {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: url("../images/level_bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px;
    font-size: 14px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.friends_upgrade section {
    display: flex;
    justify-content: center;
    align-content: center;
    gap: 10px;
}

.friends_upgrade section svg,
.friends_upgrade section article {
    color: var(--app-gold);
}

.friends_upgrade .upgrade_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--app-light-yellow);
    color: var(--app-primary);
    border-radius: 8px;
    padding: 10px 10px;
    width: 10rem;
}


/* ASSET OVERVIEW */
.asset_overview_wrapper {
    background: var(--app-asset);
    border-radius: 15px;
    margin-top: 1rem;
}


.assets_caption {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--app-primary);
    text-align: center;
    gap: 10px;
    padding: 20px;
}

.assets_caption b {
    color: var(--app-yellow);
}

.asset_container {
    background: var(--app-white);
    border-radius: 15px;
    padding: 20px;
}

.assets_level1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--app-asset);
    padding-bottom: 10px;
    margin-bottom: 1rem;
    gap: 10px;
}

.assets_level2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}


.assets_level1 article,
.assets_level2 article {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.assets_level1 article span:nth-child(1),
.assets_level2 article span:nth-child(1) {
    color: var(--app-primary);
    font-weight: 500;
}

.assets_level1 article span:nth-child(2),
.assets_level2 article span:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--app-gray);
    text-wrap: nowrap;
}


/* wealth content */
.wealth_content_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--app-white);
    border-radius: 15px;
    margin-top: 1rem;
}

.wealth_content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 20px 0px 20px 20px;
}

.wealth_content article:nth-child(1) {
    color: var(--app-primary);
    font-size: 16px;
}

.wealth_content article:nth-child(2) {
    color: var(--app-gray);
} 

.wealth_content_wrapper img {
    width: 8rem;
    height: 5rem;
}



/* profile tabs */
.profile_tabs_wrapper {
    border-radius: 15px;
    padding: 10px 20px;
    margin-top: 1rem;
    background: var(--app-white);
}

.profile_tabs_wrapper section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.profile_tabs_wrapper section article {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.profile_tabs_wrapper section article svg {
    color: var(--app-gray);
    cursor: pointer;
}

.logout {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}



/* BIND EMAIL */
.bind_email_warning {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 1rem;
}

.bind_email_warning span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--app-primary);
    gap: 10px;
    font-weight: 500;
}

.auth_suffix {
    color: var(--app-yellow);
    cursor: pointer;
}

.profile_modal_padding {
    margin: 1rem 0rem;
}

.wallet_address_binding {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--app-white);
    box-shadow: var(--app-shadow);
    padding: 10px;
    border-radius: 15px;
    margin-bottom: 0.5rem;
}

.wallet_address_binding section:nth-child(1) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.wallet_address_binding section:nth-child(1) span:nth-child(1) {
    font-size: 14px;
    color: var(--app-primary);
    font-weight: 500;
}

.wallet_address_binding section:nth-child(1) span:nth-child(2) {
    font-size: 14px;
    color: var(--app-gray);
    font-weight: 400;
    text-transform: uppercase;
}

.wallet_address_binding .address_binding_action{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.wallet_address_binding .main_net {
    color: var(--app-primary);
    font-weight: 700;
}

.loading_state {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem 0rem;
}

.loading_state article {
    color: var(--app-yellow);
    padding-top: 1rem;
    cursor: pointer;
}

.transaction_code {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 10px;
    margin-bottom: 10px;
}

.transaction_code .warning {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    gap: 10px;
    margin-left: -10px;
    padding-bottom: 10px;
}

.transaction_code section {
    font-size: 12px;
    color: var(--app-gray);
} 

.transaction_code section ol li {
    text-align: justify;
}


/* USER TERMS */
.user_terms {
    padding: 10px;
}

.user_terms article {
    font-size: 18px;
    font-weight: 500;
    color: var(--app-primary);
    padding-bottom: 1rem;
}

.user_terms ul {
    color: var(--app-gray);
    font-size: 12px;
    text-align: justify;
}

.user_terms ul li {
    padding-bottom: 10px;
}


.deposit_fund_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.deposit_fund_desc {
    color: var(--app-gray);
    font-size: 13px;
    padding: 1rem 0rem;
}

.wrapper_cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}



/* PERSONAL INFORMATION */
.personal_information {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0rem;
}

.personal_information article:nth-child(1) {
    font-weight: 700;
}