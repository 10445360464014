

/* google font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


:root {
    --app-theme: #f5f5f5;
    --app-white: #fff;
    --app-primary: #27313c;
    --app-gray: #6f7a8d;
    --app-title-gray: #848fa0;
    --app-asset: #ebecf0;
    --app-asset-color: #1d263199;
    --app-yellow: #db9c00;
    --app-light-yellow: #ffd200;
    --app-warning: rgb(157, 40, 40);
    --app-bg-linear: linear-gradient(180deg,#ffe56c,#fff6cc);
    --app-gold: #ffe4bc;
    --app-g1: #274d3a;
    --app-g2: #0067e3;
    --app-g3: #4e27df;
    --app-g4: #952699;
    --app-g5: #805414;
    --app-barCode: #46E8E4;
    --app-success: #64CC4A;
    --app-family: "Poppins", sans-serif;
    --app-transparent: transparent;
    --app-bg-gradient: linear-gradient(180deg, #ffd001, #f5b901);
    --app-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px
}


* {
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    scrollbar-width: none;
    scroll-snap-type: both mandatory;
    box-sizing: border-box;
    font-family: var(--app-family);
    font-style: normal;
}

body {
    background: var(--app-theme);
}

.ant-table-thead tr th {
    color: var(--app-primary) !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    text-transform: uppercase;
    text-wrap: nowrap !important;
}


.ant-table-tbody tr td {
    color: var(--app-title-gray) !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    text-wrap: nowrap !important;
}

.ant-spin-fullscreen {
    width: 100% !important;
    margin-left: auto;
    margin-right: auto;
}

.ant-otp {
    width: 100%;
}

.ant-otp .ant-otp-input {
    background: var(--app-theme);
    height: 40px;
}



.yellow {
    color: var(--app-yellow);
}

a {
    color: var(--app-yellow) !important;
    text-decoration: none;
    list-style: none;
}

.error_text {
    font-size: 12px;
    color: var(--app-warning);
    margin-top: -0.8rem;
    padding-bottom: 1rem;
}


.ant-drawer {
    width: 500px;
    margin-right: auto;
    margin-left: auto;
}

.ant-drawer .ant-drawer-content {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
}

.ant-drawer-content-wrapper {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.ant-drawer .ant-drawer-body {
    padding: 0px !important;
}

svg {
    color: var(--app-gray);
    cursor: pointer;
}

.height600 {
    height: 600px !important;
}


.wrapper {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
    background: var(--app-theme);
}

.pt15 {
    padding-top: 15px;
}




@media (min-width: 640px) {
    .wrapper {
        max-width: 100%;
    }
}



@media (min-width: 768px) {
    .wrapper {
        max-width: 500px;
  
    }
}


@media (min-width: 1024px) {
    .wrapper {
        max-width: 500px;
    }

    .ant-spin-fullscreen {
        width: 500px !important;
        margin-left: auto;
        margin-right: auto;
    }
}