
.transactions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: var(--app-white);
    box-shadow: var(--app-shadow);
    border-radius: 15px;
    margin-bottom: 10px;
}

.transactions:last-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: var(--app-white);
    box-shadow: var(--app-shadow);
    border-radius: 15px;
    margin-bottom: 0px;
}

.transactions .trans_left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.transactions .trans_left section {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.transactions .trans_left section span:nth-child(1) {
    color: var(--app-primary);
    font-size: 13px;
}

.transactions .trans_left section span:nth-child(2) {
    color: var(--app-title-gray);
    font-size: 13px;
}

.amount_status {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
}

.transactions .amount {
    color: var(--app-primary);
    font-weight: 700;
    gap: 10px;
}

.amount_status .PENDING {
    color: var(--app-light-yellow);
    font-size: 12px;
}


.amount_status .COMPLETED,
.amount_status .APPROVED {
    color: var(--app-success);
    font-size: 12px;
}

.amount_status .FAILED {
    color: var(--app-warning);
    font-size: 12px;
}