

.asset_profile_wrapper {
    background: url("../images/assets_bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px;
    margin-top: 2.2rem;
}

.assets_profile_container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.assets_profile_record {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    margin-top: 1rem;
}

.assets_profile_record article {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.assets_profile_container article:nth-child(1),
.assets_profile_record article span:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: var(--app-asset-color);
    text-wrap: nowrap;
}

.assets_profile_container article:nth-child(2) {
    color: var(--app-primary);
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    text-wrap: nowrap;
} 

.assets_profile_record article span:nth-child(2)  {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--app-primary);
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    text-wrap: nowrap;
} 



/* ASSET DEPOSIT AND WITHDRAWAL */
.asset_deposit_withdraw {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: var(--app-white);
    border-radius: 15px;
    margin: -1.5rem 1rem 0rem 1rem;
}

.deposit,
.withdraw {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    color: var(--app-primary);
    font-weight: 500;
    cursor: pointer;
}

.withdraw svg {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}


/* assets list css */
.assets_container_list {
    background: var(--app-white);
    padding: 20px;
    margin-top: 1rem;
    height: 100vh;
}

.assets_list_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.assets_list_title article {
    font-size: 18px;
    color: var(--app-primary);
}


.assets_list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.assets_list .assets_list_left {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.list_lift_name {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.list_lift_name span:nth-child(1) {
    color: var(--app-primary);
    font-size: 16px;
    font-weight: 700;
}

.list_lift_name span:nth-child(2) {
    color: var(--app-gray);
    font-size: 14px;
    font-weight: 400;
    margin-top: -8px;
}

.list_lift_name_right {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
}

.list_lift_name_right span:nth-child(1) {
    color: var(--app-primary);
    font-size: 16px;
    font-weight: 700;
}

.list_lift_name_right span:nth-child(2) {
    color: var(--app-gray);
    font-size: 14px;
    font-weight: 400;
    margin-top: -8px;
}