

.tab_wrapper {
    margin: 1rem 0rem;
}

.tab_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--app-yellow);
    padding: 1rem 0px 0rem 0px;
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.tab_item {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--app-primary);
    cursor: pointer;
    width: 100%;
    text-align: center;
}

.tab_item.active {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--app-white);
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    padding: 10px;
    width: 100%;
    transition: background 0.3s ease;
    z-index: 2;
}


.tab_item.active:before {
    content: "";
    position: absolute;
    top: 2rem;
    left: 0;
    width: .86rem;
    height: 100%;
    transform: translate(-50%) scaleX(-1);
    background: url("../images/tabOnRight.png") no-repeat top;
    background-size: 100%;
}

.tab_item.active::after {
    content: "";
    position: absolute;
    top: 2rem;
    right: 0;
    width: .86rem;
    height: 100%;
    transform: translate(50%);
    background: url("../images/tabOnRight.png") no-repeat top;
    background-size: 100%;
}

.tab_content {
    width: 100%;
    background: var(--app-white);
    padding: 10px 20px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}




/* table */
.custom_table {
    width: 100%;
}

.custom_table .table_head tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--app-gray);
    font-size: 12px;
    text-wrap: nowrap;
}

.custom_table .table_head tr  th {
    text-wrap: nowrap;
}


.table_body tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--app-gray);
    font-size: 14px;
    padding: 5px 0;
}

.table_body tr .name {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-weight: 400;
}

.table_body tr .name img {
    width: 40px;
    height: 40px;
}

.table_body tr .name .sub_name {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.table_body tr .name .sub_name span:nth-child(1) {
    color: var(--app-primary);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1em;
}

.table_body tr .name .sub_name span:nth-child(2) {
    color: var(--app-gray);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1em;
}



.table_body tr .price {
    color: var(--app-primary);
    font-weight: 400;
}

.table_body tr .hChange {
    color: var(--app-white);
    padding: 5px;
    border-radius: 8px;
}

.table_body tr .hChange.negative {
    background: red;
  }
  
  .table_body tr .hChange.positive {
    background: green;
  }