

/* auth top icon */

.auth__top {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    color: var(--app-gray);
}

.auth__top section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.auth__top svg {
    cursor: pointer;
}


/* auth welcome caption */
.auth__welcome {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 1rem;
}

.auth__welcome article:nth-child(1) {
    font-size: 2rem;
    color: var(--app-primary);
    font-weight: 500;
}

.auth__welcome article:nth-child(2) {
    color: var(--app-gray);
    font-weight: 400;
    font-size: 1rem;
}


/* auth form */
.auth__form__wrapper {
    background: var(--app-white);
    border-radius: 15px;
    padding: 1rem;
    margin-top: 1.5rem;
}

.auth__input {
    background: var(--app-transparent);
    padding-bottom: 1rem;
}

.auth__input section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: var(--app-theme);
    border: none;
    background: var(--app-theme);
    height: 48px;
    border-radius: 8px;
    padding: 0rem 10px;
}

.auth__input section #country__code {
    cursor: pointer;
    border: none;
    background: var(--app-transparent);
    width: 2.5rem;
    outline: none;
}

.auth__input section #country__code:focus,
.auth__input section #country__code:hover {
    outline: none;
    border: none;
}

.auth__input .ant-typography {
    color: var(--app-title-gray);
    font-size: 14px;
    padding-bottom: 0.5rem;
    font-weight: 400;
    text-transform: capitalize;
}

.auth__input .input__border {
    border: none;
    background: var(--app-theme);
    height: 48px;
    width: 100%;
}

.auth__input .input__border:focus,
.auth__input .input__border:hover,
.auth__input section:focus,
.auth__input section:hover {
    border: 1px solid var(--app-light-yellow);
}

.auth__remember {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding-bottom: 2rem;
}

.auth__remember .desc {
    color: var(--app-yellow);
    cursor: pointer;
}

.auth__btn {
    width: 100%;
    background: var(--app-light-yellow);
    height: 48px;
    border: none;
    outline: none;
    box-shadow: none;
}
.auth__btn_cancel {
    width: 100%;
    background: var(--app-primary);
    height: 48px;
    border: none;
    outline: none;
    box-shadow: none;
    color: var(--app-white);
}


.auth__btn:focus,
.auth__btn:hover {
    background: var(--app-light-yellow) !important;
}

.auth__btn_cancel:focus,
.auth__btn_cancel:hover {
    background: var(--app-primary) !important;
    color: var(--app-white) !important;
}

.auth__btn:disabled,
.auth__btn_cancel:disabled {
    background: var(--app-primary);
    color: var(--app-white);
}


.auth__btn:disabled:focus,
.auth__btn:disabled:hover,
.auth__btn_cancel:disabled:focus,
.auth__btn_cancel:disabled:hover {
    background: var(--app-primary) !important;
}


.auth__no__account {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 2rem;
    color: var(--app-primary);
}



.auth__btn__download {
    width: 100%;
    background: var(--app-theme);
    height: 48px;
    border: none;
    color: var(--app-primary);
    outline: none;
    box-shadow: none;
}

.auth__btn__download:focus,
.auth__btn__download:hover {
    background: var(--app-theme) !important;
    color: var(--app-primary) !important;
}

.auth__support {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-top: 2rem;
}

.auth__support img {
    cursor: pointer;
}


.country__codes {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 10px 0rem;
    border-bottom: 1px solid var(--app-theme);
    cursor: pointer;
}

.country_number_codes {
    padding: 0px 20px 20px 20px;
}

.country_select {
    padding: 20px 20px 0px 20px;
    position: sticky;
    top: 0;
    background: var(--app-white);
    z-index: 9;
}

.drawer__caption {
    display: flex;
    justify-content: space-between;
    background: var(--app-white);
    padding-bottom: 0.5rem;
}

.drawer__caption article {
    font-size: 18px;
    color: var(--app-primary);
    font-weight: 500;
}

.no-results {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--app-title-gray);
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--app-primary);
    border-color: var(--app-primary);
  }

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox-wrapper:focus .ant-checkbox-inner {
    border-color: var(--app-primary) !important;
    background-color: var(--app-primary) !important;
  }




  @media (min-width: 768px) {
    .auth__support {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding-top: 2rem;
    }
  }

  @media (min-width: 1024px) {
    .auth__support {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding-top: 2rem;
    }
  }