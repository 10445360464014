

.quantify_booster_container {
    background: var(--app-white);
    border-radius: 15px;
    margin-top: 1.8rem;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.quantify_booster_wrapper {
    background: url("../images//quantify_bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px;
}

.booster_time {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.booster_inner_wrapper {
    background: var(--app-white);
    border-radius: 15px;
    padding: 20px;
    margin-top: 1rem;
}

.booster_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.booster_inner section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.booster_inner section article:nth-child(1) {
    font-size: 20px;
    color: var(--app-primary);
    font-weight: 500;
} 

.booster_inner section article:nth-child(2) {
    color: var(--app-gray);
    font-weight: 400;
    text-align: center;
    font-size: 12px;
} 

.booster_accelerator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.booster_accelerator article {
    font-size: 12px;
    font-weight: 500;
}

.booster_accelerator section {
    display: flex;
    justify-content: center;
    align-items: center;
}

.booster_accelerator section article {
    color: var(--app-gray);
}


/* SINGLE START AIELATE */
.start_AIELATE {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border-radius: 15px;
    background: var(--app-light-yellow);
    color: var(--app-primary);
    font-size: 16px;
    font-weight: 500;
    margin: 1rem 0rem;
    cursor: pointer;
}


/* VIPS LEVELS */
.quantify_vip_levels {
    width: 100%;
}

.quantify_vip_levels .levels {
    font-size: 16px;
    color: var(--app-primary);
    padding-bottom: 0.5rem;
    font-weight: 500;
}

.vip_level_cards_wrapper {
    display: flex;
    overflow-x: auto;
    gap: 10px;
}


.vip_level_cards {
    width: 90%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px;
    padding: 20px;
    cursor: pointer;
}

.vip_level_cards:last-child {
    width: 100%;
}


.vip_level_cards .gs {
    font-size: 35px;
    color: var(--app-g1);
    font-weight: 700;
}

.vip_level_cards:nth-child(2) .gs:nth-child(1),
.vip_level_cards:nth-child(2) .gs_lock:nth-child(2) {
    color: var(--app-g2);
}

.vip_level_cards:nth-child(3) .gs:nth-child(1),
.vip_level_cards:nth-child(3) .gs_lock:nth-child(2) {
    color: var(--app-g3);
}

.vip_level_cards:nth-child(4) .gs:nth-child(1),
.vip_level_cards:nth-child(4) .gs_lock:nth-child(2) {
    color: var(--app-g4);
}

.vip_level_cards:nth-child(5) .gs:nth-child(1),
.vip_level_cards:nth-child(5) .gs_lock:nth-child(2) {
    color: var(--app-g5);
}

.vip_level_cards:nth-child(6) .gs:nth-child(1),
.vip_level_cards:nth-child(6) .gs_lock:nth-child(2) {
    color: var(--app-white);
}


.vip_level_cards .gs_lock {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    color: var(--app-g1);
    width: 20rem;
}



/* VIP GRADES */
.quantify_vip_grades {
    background: var( --app-asset);
    border-radius: 15px;
    margin-top: 1rem;

}

.grade_position {
    color: var(--app-primary);
    font-size: 16px;
    font-weight: 500;
    padding: 20px;
}

.grade_position_content {
    background: var(--app-white);
    padding: 10px 20px;
    border-radius: 15px;
}

.grade_position_content_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1px 0rem;
}

.grade_position_content_inner article:nth-child(1) {
    color: var(--app-gray);
    font-size: 14px;
}



@media (min-width: 768px) {
    .vip_level_cards {
        width: 22rem;
    }
}