


.team_summary_wrapper {
    background: var(--app-white);
    border-radius: 15px;
    margin-top: 2rem;
}

.team_summary_inner {
    background: url("../images/team_bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px;
    padding: 20px;
}

.team_summary_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    font-size: 13px;
    font-weight: 400;
}

.team_summary_top span:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-wrap: nowrap;
}

.team_summary_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 2rem;
}

.team_summary_bottom  section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.team_summary_bottom  section span:nth-child(2) {
    color: var(--app-asset-color);
    font-size: 12px;
    font-weight: 400;
}

.team_earning_analysis {
    background: var(--app-white);
    border-radius: 15px;
    padding: 20px;
    margin-top: 1rem;
}

.team_earning_analysis_tb {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    border-top: 1px solid var(--app-asset);
    border-bottom: 1px solid var(--app-asset);
    padding: 1rem 0rem;
    margin-bottom: 1rem;
}

.team_earning_analysis_tb span:nth-child(1) {
    color: var(--app-gray);
}

.team_earning_analysis_tb span:nth-child(1) b {
    color: var(--app-primary);
}

.team_earning_analysis_tb span:nth-child(2) {
    color: var(--app-yellow);
}

.team_earning_analysis .assets_level2 article span:nth-child(2) {
    font-size: 12px;
}


/* generation data */
.generation_data_wrapper {
    background: var(--app-white);
    padding: 20px;
    border-radius: 15px;
    margin-top: 1rem;
}


.effective_user_data .title {
    color: var(--app-primary);
    font-size: 14px;
    padding-bottom: 1rem;
}

.gene_chart_data {
    margin-bottom: -5rem;
}

.gene_chart_data .title {
    color: var(--app-primary);
    font-size: 14px;
}

.generation_data {
    border-bottom: 1px solid var(--app-asset);
    padding: 1rem 0rem;
}

.generation_data:last-child {
    padding-bottom: 0rem;
    border: none;
}

.generation_data article {
    font-size: 12px;
    color: var(--app-primary);
    font-weight: 500;
    padding-bottom: 0.5rem;
}

.gen_data_content {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 10px;
}

.gen_data {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.gen_data article:nth-child(1) {
    color: var(--app-primary);
    font-size: 13px;
}

.gen_data article:nth-child(2) {
    color: var(--app-gray);
    font-size: 12px;
    font-weight: 400;
}

.generation_data_wrapper > div.effective_user_data > div:nth-child(2) > div > section:nth-child(1) > article:nth-child(1) {
    color: var(--app-yellow);
}

.generation_data_wrapper > div.effective_user_data > div:nth-child(3) > div > section:nth-child(1) > article:nth-child(1) {
    color: var(--app-yellow);
}

.generation_data_wrapper > div.effective_user_data > div:nth-child(4) > div > section:nth-child(1) > article:nth-child(1) {
    color: var(--app-yellow);
}